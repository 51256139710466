import { Box } from "@gogeo-io/ui-library";
import { Outlet } from "react-router-dom";

export function ParamsHistoryPage() {
  return (
    <>
      <Box css={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <Outlet />
      </Box>
    </>
  );
}
