import { selectHistoryOfParamsStatus } from "@/features/params/store/historyOfParamsSlice";
import { Params } from "@core/models/params.model";
import { TimelineListOfParams } from "@features/history/components/TimelineListOfParams";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HistoryPageSkeleton } from "./HistoryPageSkeleton";

interface TimelineOfParamsProps {
  params: Params[];
}

export const TimelineOfParams = ({ params }: TimelineOfParamsProps) => {
  const status = useSelector(selectHistoryOfParamsStatus);

  return (
    <Wrapper>
      {status === "success" ? (
        <TimelineListOfParams params={params} />
      ) : (
        <HistoryPageSkeleton />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: ${(props) => props.theme.space[4]};
`;
