import { User } from "@/core/models/user.model";
import { Table } from "antd";

interface ParamsOfSalesmansTableProps {
  columns: any;
  data: any;
  isLoading: boolean;
}

export function ParamsOfSalesmansTable({
  columns,
  data,
  isLoading,
}: ParamsOfSalesmansTableProps) {
  const generateRowKey = (record: User) => {
    return `${record.id}-${record.name}`;
  };

  return (
    <Table
      bordered
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      rowKey={generateRowKey}
      scroll={{ x: "max-content", y: "calc(100% - 120px)" }}
    />
  );
}
