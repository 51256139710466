import { getClientById } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ChangeParamsForm } from "../components/ChangeParamsForm";
import { selectClientToChangeParams } from "../store/clientSelectedToChangeParamsSlice";
import { selectSelectedSalesmanToChangeClientParams } from "../store/paramsSlice";
import { ChangeParamsFormSkeleton } from "./ChangeParamsFormSkeleton";

interface ChangeParamsPageProps {
  onUpdateParams: () => void;
}

export function ChangeParamsPage({ onUpdateParams }: ChangeParamsPageProps) {
  const [clientParamsIsLoaded, setClientParamsIsLoaded] = useState(false);

  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeClientParams
  );

  const { client_id } = useParams();
  const dispatch = useAppDispatch();

  async function loadClientAndParams() {
    setClientParamsIsLoaded(false);

    if (client_id) {
      await dispatch(
        getClientById({
          client_id: client_id,
          shouldBringParams: true,
          user_id: selectedSalesmanToChangeParams.id,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setClientParamsIsLoaded(true);
          await dispatch(selectClientToChangeParams(res.payload));
        }
      });
    } else {
      setClientParamsIsLoaded(true);
    }
  }

  useEffect(() => {
    loadClientAndParams();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$3", marginTop: "$1" }}>
      {clientParamsIsLoaded ? (
        <ChangeParamsForm onUpdateParams={onUpdateParams} />
      ) : (
        <ChangeParamsFormSkeleton />
      )}
    </Flex>
  );
}
