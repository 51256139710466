import { LastHistoryOfParamsByUser } from "@/core/models/params.model";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { setSelectedSalesmanToChangeClientParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface HistoryParamsByUsersTableProps {
  columns: any;
  data: any;
  isLoading: boolean;
}

export function HistoryParamsByUsersTable({
  columns,
  data,
  isLoading,
}: HistoryParamsByUsersTableProps) {
  const selectedClient = useSelector(selectClient);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const generateRowKey = (record: LastHistoryOfParamsByUser) => {
    return `${record.user.id}-${record.user.name}`;
  };

  const handleClickOnUser = async (record: LastHistoryOfParamsByUser) => {
    await dispatch(setSelectedSalesmanToChangeClientParams(record.user));
    navigate(
      `/info/client/${selectedClient.id}/params-history/${record.user.id}`
    );
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        onRow={(record: LastHistoryOfParamsByUser) => ({
          onClick: () => handleClickOnUser(record),
        })}
        style={{ cursor: "pointer" }}
        rowKey={generateRowKey}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  position: "sticky",
                  top: -25,
                  zIndex: 1,
                }}
              />
            ),
            table: (props) => (
              <tr
                {...props}
                style={{
                  cursor: "pointer",
                }}
              />
            ),
          },
        }}
      />
    </>
  );
}
