import RLSSkeleton from "react-loading-skeleton";

export function ClientProfileParametersSkeleton() {
  return (
    <>
      <RLSSkeleton width={250} height={15} />
      <RLSSkeleton width={320} height={15} />
      <RLSSkeleton width={140} height={15} />
      <RLSSkeleton width={160} height={15} />
      <RLSSkeleton width={320} height={40} />
      <RLSSkeleton width={250} height={15} />
      <RLSSkeleton width={320} height={15} />
      <RLSSkeleton width={140} height={15} />
    </>
  );
}
