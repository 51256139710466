import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
	${normalize};

	:root {
		--header-height: 4rem;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: none;
		border: none;
    scroll-behavior: smooth;

		&:disabled {
			pointer-events: none;
		}
	}

	.ant-table-wrapper {
		height: 100%;

		.ant-spin-nested-loading {
			height: 100%;

			.ant-spin-container {
				height: 100%;

				.ant-table {
					height: 100%;

					.ant-table-container {
						height: 100%;

						
					}
				}
			}
		}
	}
	
	body {
		background: ${(props) => props.theme.colors.white};
		color: ${(props) => props.theme.colors.gray700};
		font: 400 16px Roboto, sans-serif, system-font;
    -webkit-font-smoothing: antialiased;

		scrollbar-width: auto;
  	scrollbar-color: ${(props) => props.theme.colors.gray200} ${(props) =>
  props.theme.colors.white};

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-track {
			background: ${(props) => props.theme.colors.white};
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${(props) => props.theme.colors.gray200};
			border-radius: 20px;
			border: 3px solid ${(props) => props.theme.colors.white};
		}
	}

	a {
		text-decoration: none;
		color: ${(props) => props.theme.colors.gray700};
	}

	code {
		background: ${(props) => props.theme.colors.gray100};
		padding: ${(props) => props.theme.space[1]};
		border-radius: 2px;
	}

	.emoji-picker {
		z-index: 9999;
		height: auto;
    max-height: 400px;
    overflow-y: auto;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
