import { Flex } from "@gogeo-io/ui-library";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { FormInputField } from "./FormInputField";
import { FormLabel } from "./FormLabel";

interface FormControllerInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder: string;
  type?: "text" | "number";
}

export function FormControllerInput<T extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  type = "number",
}: FormControllerInputProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <FormLabel label={label} />
          <FormInputField
            placeholder={placeholder}
            error={error}
            field={field}
            type={type}
          />
        </Flex>
      )}
    />
  );
}
