import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

interface ClientProfileSkeletonProps {
  showAppBar?: boolean;
}

export function ClientProfileSkeleton({
  showAppBar = false,
}: ClientProfileSkeletonProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      {showAppBar && <RLSSkeleton height={58} />}

      <Flex css={{ gap: "$4" }}>
        <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
          <Flex
            css={{
              flexDirection: "column",
              gap: "0.625rem",
              padding: "0.625rem",
            }}
          >
            <RLSSkeleton width={150} height={22} />

            <RLSSkeleton height={23} />

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>
          </Flex>

          <Flex
            css={{
              flexDirection: "column",
              gap: "0.625rem",
              padding: "0.625rem",
            }}
          >
            <RLSSkeleton width={150} height={22} />

            <RLSSkeleton height={23} />

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>

            <Flex css={{ flexDirection: "column" }}>
              <RLSSkeleton width={110} height={15} />
              <RLSSkeleton width={250} height={20} />
            </Flex>
          </Flex>
        </Flex>
        <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
          <Flex css={{ flexDirection: "column" }}>
            <RLSSkeleton height={65} />

            <Flex
              css={{
                flexDirection: "column",
                gap: "0.625rem",
                padding: "0.625rem",
              }}
            >
              <RLSSkeleton width={150} height={22} />

              <RLSSkeleton height={23} />

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>
            </Flex>
          </Flex>

          <Flex css={{ flexDirection: "column" }}>
            <RLSSkeleton height={65} />

            <Flex
              css={{
                flexDirection: "column",
                gap: "0.625rem",
                padding: "0.625rem",
              }}
            >
              <RLSSkeleton width={150} height={22} />

              <RLSSkeleton height={23} />

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>
            </Flex>
          </Flex>

          <Flex css={{ flexDirection: "column" }}>
            <RLSSkeleton height={65} />

            <Flex
              css={{
                flexDirection: "column",
                gap: "0.625rem",
                padding: "0.625rem",
              }}
            >
              <RLSSkeleton width={150} height={22} />

              <RLSSkeleton height={23} />

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>

              <Flex css={{ flexDirection: "column" }}>
                <RLSSkeleton width={110} height={15} />
                <RLSSkeleton width={250} height={20} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
