import { ParamsFreq } from "@/components/ParamsFreq";
import { ShowPreferedDays } from "@/components/PreferedDays/ShowPreferedDays";
import { PreferedDaysAndWeeksSubtitle } from "@/components/PreferedDaysAndWeeksSubtitle";
import { PreferedWeeks } from "@/components/PreferedWeeks";
import {
  getClientById,
  selectClientParams,
  selectClientWithParams,
} from "@/features/clientInfo/store/clientSlice";
import { selectUser } from "@core/store/users/usersSlice";
import { ChangeParamsDialog } from "@features/params/components/ChangeParamsDialog";
import {
  removeAllClientToChangeParams,
  selectClientToChangeParams,
} from "@features/params/store/clientSelectedToChangeParamsSlice";
import { setSelectedSalesmanToChangeClientParams } from "@features/params/store/paramsSlice";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import useAppDispatch from "@hooks/useAppDispatch";
import FiberNew from "@mui/icons-material/FiberNew";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useClientInfoMixpanel } from "../../hooks/useClientInfoMixpanel";
import { ClientProfileParametersSkeleton } from "./ClientProfileParametersSkeleton";

export function ClientProfileParameters() {
  const [clientParamsIsLoaded, setClientParamsIsLoaded] = useState(false);
  const [clientParamsHaveError, setClientParamsHaveError] = useState(false);

  const selectedClientParams = useSelector(selectClientParams);
  const selectedClientWithParams = useSelector(selectClientWithParams);
  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { clientProfileParametersClickedEvent } = useClientInfoMixpanel();
  const { client_id } = useParams();

  const handleSetClientToChangeParams = async () => {
    await dispatch(removeAllClientToChangeParams());
    await dispatch(setSelectedSalesmanToChangeClientParams(user));
  };

  async function loadClientAndParams() {
    setClientParamsIsLoaded(false);
    setClientParamsHaveError(false);

    if (client_id) {
      await dispatch(
        getClientById({
          client_id: client_id,
          shouldBringParams: true,
          user_id: user.id,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setClientParamsIsLoaded(true);
          await dispatch(selectClientToChangeParams(res.payload));
        } else {
          setClientParamsHaveError(true);
          toast.error(
            "Houve um problema ao carregar os dados de parametrização do cliente"
          );
        }
      });
    }
  }

  const handleUpdateParams = async () => {
    loadClientAndParams();
  };

  useEffect(() => {
    loadClientAndParams();
  }, []);

  return (
    <>
      {!clientParamsHaveError && (
        <Accordion type="single" collapsible width="full" defaultValue="item-1">
          <AccordionItem value="item-1">
            <AccordionTrigger
              onClick={() =>
                clientProfileParametersClickedEvent(
                  selectedClientParams,
                  client_id
                )
              }
            >
              <Text>Parametrização</Text>
            </AccordionTrigger>
            <AccordionContent
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <AccordionContainerContent>
                {clientParamsIsLoaded ? (
                  <>
                    <ParamsFreq freq={selectedClientParams.freq} />
                    <PreferedWeeks params={selectedClientParams} isBlocked />
                    <Text css={{ color: "$gray500" }}>
                      Não agendável:
                      <strong>
                        {selectedClientParams.non_schedulable ? "Sim" : "Não"}
                      </strong>
                    </Text>
                    <div className="item">
                      <Text css={{ color: "$gray500" }}>
                        {" "}
                        Dias preferidos no mês:
                      </Text>

                      <ShowPreferedDays
                        isBlocked
                        daysWeekPrefReceived={
                          selectedClientParams.days_week_pref
                        }
                      />
                    </div>
                    <PreferedDaysAndWeeksSubtitle />
                    <div className="item">
                      <Flex
                        css={{ color: "$primary500", alignItems: "center" }}
                      >
                        <Text css={{ color: "$gray500" }}>
                          Regra Comercial Aplicada:
                        </Text>
                        <FiberNew color="inherit" />
                      </Flex>
                      <Flex css={{ flexDirection: "column" }}>
                        <Text css={{ color: "$gray500" }}>
                          Observações: {selectedClientParams.observations}
                        </Text>
                      </Flex>
                    </div>
                    <div className="item">
                      <ChangeParamsDialog onUpdateParams={handleUpdateParams}>
                        <Text
                          css={{
                            color: "$primary500",
                            textAlign: "left",
                            width: "fit-content",
                            textDecoration: "underline",
                          }}
                          onClick={handleSetClientToChangeParams}
                        >
                          Editar parâmetros do cliente
                        </Text>
                      </ChangeParamsDialog>
                    </div>
                  </>
                ) : (
                  <ClientProfileParametersSkeleton />
                )}
              </AccordionContainerContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
}

export const AccordionContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .item {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[2]};
  }
`;
