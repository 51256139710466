import { Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { FormInputField } from "./FormInputField";
import { FormLabel } from "./FormLabel";
import { FormRadioBooleanField } from "./FormRadioBooleanField";

interface FormControllerInputWithUndefinedProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  defaultValue: number;
  placeholder: string;
  type?: "text" | "number";
}

export function FormControllerInputWithUndefined<T extends FieldValues>({
  control,
  name,
  label,
  defaultValue,
  placeholder,
  type,
}: FormControllerInputWithUndefinedProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const [isUndefined, setIsUndefined] = useState(true);

        const handleRadioChange = (value) => {
          setIsUndefined(value === undefined);
          if (value === undefined) {
            field.onChange(undefined);
          } else {
            field.onChange(defaultValue); //default value
          }
        };

        return (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <FormLabel label={label} />

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <FormRadioBooleanField
                onChange={handleRadioChange}
                value={undefined}
                options={[
                  { title: "Não alterar", value: undefined },
                  { title: "Definir valor", value: true },
                ]}
              />

              {!isUndefined && (
                <FormInputField
                  placeholder={placeholder}
                  error={error}
                  field={field}
                  type={type}
                />
              )}
            </Flex>
          </Flex>
        );
      }}
    />
  );
}
