import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedSalesmansToChangeParams } from "../../store/paramsSlice";

export function CountOfSalesmansToChangeParams() {
  const [value, setValue] = useState("");

  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );
  const { formatNumberToDecimal } = useFormatPrice();

  useEffect(() => {
    if (!isEmpty(selectedSalesmansToChangeParams)) {
      const title =
        selectedSalesmansToChangeParams.length <= 1
          ? selectedSalesmansToChangeParams[0].name
          : `${formatNumberToDecimal(
              selectedSalesmansToChangeParams.length
            )} clientes selecionados`;
      setValue(title);
    } else {
      setValue("Nenhum Cliente selecionado");
    }
  }, [selectedSalesmansToChangeParams]);

  return (
    <Flex css={{ gap: "0.5rem", alignItems: "center" }}>
      <Text>Cliente(s):</Text>

      <Text size="body1" bold css={{ color: "$black" }}>
        {value}
      </Text>
    </Flex>
  );
}
