import { AppDispatch } from "@/core/store/store";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  getChannels,
  selectAvailableChannelsStatus,
} from "@/features/attendance/store/feedbackSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { ClientsPageSkeleton } from "@/features/clientInfo/components/ClientsPageSkeleton";
import { PageLayout } from "@/layouts/PageLayout";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ClientProfile } from "../components/ClientProfile";
import { ClientProfileSkeleton } from "../components/ClientProfileSkeleton";
import { getClientById } from "../store/clientSlice";

export const ClientProfilePage = () => {
  const [clientInfoIsLoaded, setClientInfoIsLoaded] = useState(false);

  const selectedList = useSelector(selectSelectedList);
  const user = useSelector(selectUser);
  const availableChannelsStatus = useSelector(selectAvailableChannelsStatus);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { client_id } = useParams();

  useEffect(() => {
    setClientInfoIsLoaded(false);

    async function loadClientAndParams() {
      if (client_id) {
        await dispatch(
          getClientById({
            client_id: client_id,
            shouldBringParams: false,
          })
        ).then(async (obj) => {
          if (obj.meta.requestStatus === "rejected") {
            navigate(
              `/attendance/${
                !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
                  ? selectedList.slug
                  : `${user.account_id}_${user.id}_clientes`
              }`
            );
          } else {
            setClientInfoIsLoaded(true);
          }
        });
      }
    }

    async function loadChannels() {
      await dispatch(getChannels());
    }

    loadClientAndParams();
    loadChannels();
  }, [client_id]);

  return (
    <PageLayout
      leftSidebar={
        clientInfoIsLoaded && availableChannelsStatus === "success" ? (
          <ClientProfile />
        ) : (
          <ClientsPageSkeleton />
        )
      }
      content={
        clientInfoIsLoaded && availableChannelsStatus === "success" ? (
          <Outlet />
        ) : (
          <ClientProfileSkeleton showAppBar />
        )
      }
    ></PageLayout>
  );
};
