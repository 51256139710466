import { selectSalesmansRelatedToUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import {
  selectSelectedSalesmansToChangeParams,
  setSelectedSalesmansToChangeParams,
} from "../store/paramsSlice";

export function SelectAllSalesmansToChangeParamsCheckbox() {
  const salesmansRelatedToUser = useSelector(selectSalesmansRelatedToUser);
  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();

  const handleSetAllClients = async (value: boolean) => {
    if (value) {
      await dispatch(
        setSelectedSalesmansToChangeParams(salesmansRelatedToUser)
      );
    } else {
      await dispatch(setSelectedSalesmansToChangeParams([]));
    }
  };

  const allSalesmansAreSelectedToChangeParams =
    selectedSalesmansToChangeParams.length === salesmansRelatedToUser.length;

  return (
    <Checkbox
      checked={allSalesmansAreSelectedToChangeParams}
      onCheckedChange={(value) => handleSetAllClients(Boolean(value))}
      css={{ cursor: "pointer" }}
    />
  );
}
