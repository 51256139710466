import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { User, UserRole } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { EnableRuptureRule } from "@/features/ruptureRules/components/EnableRuptureRule";
import { useRuptureRuleMixpanel } from "@/features/ruptureRules/hooks/useRuptureRuleMixpanel";
import {
  selectRuptureRule,
  setSelectRuptureRule,
} from "@/features/ruptureRules/store/ruptureRuleSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ProfileSubMenuItem } from "./ProfileSubMenuItem";

export interface MenuItem {
  baseUrl: string;
  text: string;
  url?: string;
  additionalActions?: () => void;
  additionalHeaderComponent?: ReactElement;
  rolesThatCannotSeeItem?: UserRole[];
  userDontHavePermissionToSee?: boolean;
}

export interface ProfileMenuItem extends MenuItem {
  children?: MenuItem[];
}

const getMenuItems = (
  user: User,
  ruptureRulesNotHaveError: boolean
): ProfileMenuItem[] => {
  const rule = useSelector(selectRuptureRule);
  const { rupture_rule } = rule;

  const { ruptureRuleSelectedEvent } = useRuptureRuleMixpanel();
  const dispatch = useAppDispatch();

  const menuItems: ProfileMenuItem[] = [
    {
      baseUrl: "/user-profile",
      url: "/user-profile",
      text: "Minhas informações",
      children: [
        {
          baseUrl: "/info",
          url: "/user-profile/info",
          text: "Perfil",
        },
        {
          baseUrl: "/permissions",
          url: "/user-profile/permissions",
          text: "Permissões",
        },
        {
          baseUrl: "/pre-defined-messages",
          url: "/user-profile/pre-defined-messages",
          text: "Mensagens Pré Definidas",
        },
        {
          baseUrl: "/attendance-params",
          url: "/user-profile/attendance-params",
          text: "Parâmetros de atendimento",
        },
        // {
        //   baseUrl: "/business-goals",
        //   url: "/user-profile/business-goals",
        //   text: "Objetivos de negócio",
        // },
        // {
        //   baseUrl: "/attendance-justifications",
        //   url: "/user-profile/attendance-justifications",
        //   text: "Justifications de atendimento",
        // },
      ],
    },
    {
      baseUrl: "/user-profile/security",
      url: "/user-profile/security",
      text: "Senha e Segurança",
    },
    {
      baseUrl: "/integrations",
      url: "/integrations/partners",
      text: "Integrações",
      rolesThatCannotSeeItem: ["SALESMAN"],
      children: [
        {
          baseUrl: "/partners",
          url: "/integrations/partners/list",
          text: "Parceiros",
        },
        {
          baseUrl: "/services",
          url: "/integrations/services/list",
          text: "Serviços Disponíveis",
        },
      ],
    },
    {
      baseUrl: "/users-managment",
      url: "/users-managment",
      text: "Gestão de Usuários",
      rolesThatCannotSeeItem: ["SALESMAN"],
    },
    {
      baseUrl: "/params",
      url: "/params/change-params",
      text: "Gestão de Atendimento",
      children: [
        {
          baseUrl: "/change-params-account",
          url: "/params/change-params-account",
          text: "Parâmetros de Conta",
          rolesThatCannotSeeItem: ["SUPERVISOR", "SALESMAN"],
        },
        {
          baseUrl: "/change-params-salesman",
          url: "/params/change-params-salesman",
          text: "Parâmetros dos Vendedores",
          rolesThatCannotSeeItem: ["SALESMAN"],
        },
        {
          baseUrl: "/change-params-clients",
          url: "/params/change-params-clients",
          text: "Parâmetros dos Clientes",
          userDontHavePermissionToSee:
            user.role === "SALESMAN" && !user.permissions["change_params"],
        },
      ],
    },
  ];

  if (user.role === "ADMIN" && ruptureRulesNotHaveError) {
    menuItems.push({
      baseUrl: "/rupture-rule",
      url:
        rupture_rule?.relationships?.length > 0
          ? `/rupture-rule/${rupture_rule.relationships[0].id}`
          : "/rupture-rule/create-rupture-rule",
      text: "Gestão de Ruptura",
      userDontHavePermissionToSee: !user.permissions["update_rupture_rule"],
      rolesThatCannotSeeItem: ["SALESMAN", "MANAGER", "SUPERVISOR"],
      additionalHeaderComponent: <EnableRuptureRule />,
      children: rule.enabled
        ? [
            {
              baseUrl: "/create-rupture-rule",
              url: "/rupture-rule/create-rupture-rule",
              text: "Criar Nova Regra",
            },
            ...(rupture_rule?.relationships?.map((rule) => ({
              baseUrl: `/${rule.id}`,
              url: `/rupture-rule/${rule.id}`,
              text: rule.name,
              additionalActions: async () => {
                await dispatch(setSelectRuptureRule(rule));
                ruptureRuleSelectedEvent(rule);
              },
            })) || []),
          ]
        : [],
    });
  }

  return menuItems;
};

interface ProfileSubMenuListProps {
  ruptureRulesNotHaveError: boolean;
}

export function ProfileSubMenuList({
  ruptureRulesNotHaveError,
}: ProfileSubMenuListProps) {
  const user = useSelector(selectUser);

  const location = useLocation();

  const isRouteActive = (url: string) => {
    return location.pathname.includes(url);
  };

  const showMenuItems = (items: ProfileMenuItem[]): JSX.Element => {
    return (
      <>
        {items.map((item) => {
          if (
            item.rolesThatCannotSeeItem &&
            item.rolesThatCannotSeeItem.includes(user.role)
          ) {
            return null;
          }

          if (item.userDontHavePermissionToSee) {
            return null;
          }

          if (item.children !== undefined && item.children.length > 0) {
            return (
              <ProfileSubMenuItem
                key={item.url}
                text={item.text}
                url={item.url}
                baseUrl={item.baseUrl}
                isRouteActive={isRouteActive}
                menuItems={item.children}
                additionalActions={item.additionalActions}
                additionalHeaderComponent={item.additionalHeaderComponent}
              />
            );
          } else {
            return (
              <LinkToAnotherTab url={`/profile${item.url}`} key={item.url}>
                <ProfileSubMenuItem
                  text={item.text}
                  url={item.url}
                  baseUrl={item.baseUrl}
                  isRouteActive={isRouteActive}
                  additionalActions={item.additionalActions}
                  additionalHeaderComponent={item.additionalHeaderComponent}
                />
              </LinkToAnotherTab>
            );
          }
        })}
      </>
    );
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      {showMenuItems(getMenuItems(user, ruptureRulesNotHaveError))}
    </Flex>
  );
}
