import { Caption, Checkbox, Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";

interface DayWithRuptureFormFieldProps {
  value: number;
  text: string;
}

export function DayWithRuptureFormFieldDisabledToChange({
  value,
  text,
}: DayWithRuptureFormFieldProps) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value !== undefined && value === 1 ? true : false);
  }, [value]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox checked={isChecked} css={{ cursor: "not-allowed" }} />
      <Caption css={{ color: "$gray600" }}>{text}</Caption>
    </Flex>
  );
}
