import { Caption, Checkbox, Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";

interface DayWithRuptureFormFieldProps {
  value: number;
  option: string;
  text: string;
  onChange: (option: string, newValue: number) => void;
}

export function DayWithRuptureFormField({
  value,
  option,
  text,
  onChange,
}: DayWithRuptureFormFieldProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (v: boolean) => {
    if (v) {
      onChange(option, 1);
      setIsChecked(true);
    } else {
      onChange(option, 0);
      setIsChecked(false);
    }
  };

  useEffect(() => {
    setIsChecked(value !== undefined && value === 1 ? true : false);
  }, [value]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox
        checked={isChecked}
        onCheckedChange={(v) => handleCheckboxChange(Boolean(v))}
      />
      <Caption css={{ color: "$gray600" }}>{text}</Caption>
    </Flex>
  );
}
