import { User } from "@/core/models/user.model";
import {
  addSelectedSalesmanToChangeParams,
  removeSelectedSalesmanToChangeParams,
  selectSelectedSalesmansToChangeParams,
} from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface SelectClientToChangeParamsProps {
  user: User;
}

export function SelectSalesmanToChangeParams({
  user,
}: SelectClientToChangeParamsProps) {
  const [checked, setChecked] = useState(false);

  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();

  const handleCheckboxChange = async (checked: boolean, user: User) => {
    if (checked) {
      await dispatch(addSelectedSalesmanToChangeParams(user));
    } else {
      await dispatch(removeSelectedSalesmanToChangeParams(user));
    }
  };

  useEffect(() => {
    const checkedIsMarked = selectedSalesmansToChangeParams.some(
      (u) => u.id === user.id
    );
    setChecked(checkedIsMarked);
  }, [selectedSalesmansToChangeParams]);

  return (
    <>
      <Checkbox
        checked={checked}
        onCheckedChange={(checked) =>
          handleCheckboxChange(Boolean(checked), user)
        }
        css={{ cursor: "pointer" }}
      />
    </>
  );
}
