import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

export function ChangeParamsFormSkeleton() {
  return (
    <Container>
      <ContainerWithoutActions>
        <RLSSkeleton width={300} height={25} />
        <RLSSkeleton width={250} height={27} />

        <RLSSkeleton width={240} height={20} />
        <RLSSkeleton width={170} height={20} />

        <RLSSkeleton width={240} height={20} />
        <RLSSkeleton width={170} height={20} />

        <RLSSkeleton width={240} height={20} />
        <RLSSkeleton width={170} height={20} />

        <RLSSkeleton width={240} height={20} />
        <RLSSkeleton height={32} />
      </ContainerWithoutActions>

      <Flex css={{ gap: "1rem" }}>
        <RLSSkeleton height={50} />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[5]};
`;

const ContainerWithoutActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
  overflow: scroll;
  max-height: 500px;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    max-height: 400px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    max-height: 300px;
  }
`;
