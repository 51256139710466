import { useMediaQuery } from "@/hooks/useMediaQuery";
import {
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import styled from "styled-components";

interface TimelineObservationsCellProps {
  observation: string;
}

export function TimelineObservationsCell({
  observation,
}: TimelineObservationsCellProps) {
  const isLaptop = useMediaQuery({ screen: "laptop" });

  const renderObservation = (observation: string) => {
    if (!isLaptop) {
      if (observation.length > 25) {
        return `${observation.slice(0, 24)}...`;
      }
    } else {
      if (observation.length > 16) {
        return `${observation.slice(0, 15)}...`;
      }
    }

    return observation;
  };

  return (
    <Container>
      <Flex css={{ flexDirection: "column", alignItems: "flex-start" }}>
        <Tooltip>
          <TooltipTrigger
            style={{ background: "transparent", cursor: "pointer" }}
          >
            <Text css={{ textAlign: "center" }}>
              {renderObservation(observation) ?? "-"}
            </Text>
          </TooltipTrigger>
          <TooltipContent
            style={{ maxWidth: "500px" }}
            aria-label={observation}
          >
            {observation}
          </TooltipContent>
        </Tooltip>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;
