import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { Box, Button } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectClient } from "../store/clientSlice";
import UI from "../ui";

type Tabs =
  | "client-info"
  | "history"
  | "products-suggestion"
  | "notes"
  | "params-history";
interface ClientInfoTabsProps {
  route: Tabs;
  children: ReactNode;
}

export const ClientInfoTabs = ({ route, children }: ClientInfoTabsProps) => {
  const navigate = useNavigate();
  const selectedClient = useSelector(selectClient);

  const onPageChange = (tab: Tabs) =>
    navigate(`/info/client/${selectedClient.id}/${tab}`);

  return (
    <>
      {!isUndefined(selectedClient) && (
        <UI.ClientInfoTabs>
          <UI.ClientHistoryNavbar>
            <LinkToAnotherTab
              url={`/info/client/${selectedClient.id}/client-info`}
            >
              <Button
                variant={route === "client-info" ? "solid" : "ghost"}
                onClick={() => onPageChange("client-info")}
              >
                Informações do cliente
              </Button>
            </LinkToAnotherTab>

            <LinkToAnotherTab url={`/info/client/${selectedClient.id}/history`}>
              <Button
                variant={route === "history" ? "solid" : "ghost"}
                onClick={() => onPageChange("history")}
              >
                Histórico
              </Button>
            </LinkToAnotherTab>

            <LinkToAnotherTab
              url={`/info/client/${selectedClient.id}/products-suggestion`}
            >
              <Button
                variant={route === "products-suggestion" ? "solid" : "ghost"}
                onClick={() => onPageChange("products-suggestion")}
              >
                Sugestão de Produtos
              </Button>
            </LinkToAnotherTab>

            <LinkToAnotherTab url={`/info/client/${selectedClient.id}/notes`}>
              <Button
                variant={route === "notes" ? "solid" : "ghost"}
                onClick={() => onPageChange("notes")}
              >
                Minhas Notas
              </Button>
            </LinkToAnotherTab>

            <LinkToAnotherTab
              url={`/info/client/${selectedClient.id}/params-history/list`}
            >
              <Button
                variant={route === "params-history" ? "solid" : "ghost"}
                onClick={() => onPageChange("params-history")}
              >
                Parâmetros de atendimento
              </Button>
            </LinkToAnotherTab>
          </UI.ClientHistoryNavbar>

          <Box
            css={{
              width: "100%",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            {<>{children}</>}
          </Box>
        </UI.ClientInfoTabs>
      )}
    </>
  );
};
