import { User } from "@/core/models/user.model";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Caption,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface UserOnParamsOfSalesmanCellProps {
  user: User;
}

export function UserOnParamsOfSalesmanCell({
  user,
}: UserOnParamsOfSalesmanCellProps) {
  return (
    <Flex css={{ flexDirection: "row", gap: "$1", alignItems: "center" }}>
      <Flex>
        {!isEmpty(user.user_photo) ? (
          <Avatar size="medium">
            {user.user_photo.photo_sm_url ? (
              <AvatarImage src={user.user_photo.photo_sm_url} />
            ) : (
              <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
            )}
          </Avatar>
        ) : (
          <Avatar size="medium">
            <AvatarFallback>GO</AvatarFallback>
          </Avatar>
        )}
      </Flex>

      <Flex
        css={{
          flexDirection: "column",
          gap: "0",
          padding: "0",
          alignItems: "flex-start",
        }}
      >
        <Heading size="subtitle1">{user.name}</Heading>

        <Caption css={{ mt: "-6px" }}>{user.email}</Caption>
      </Flex>
    </Flex>
  );
}
