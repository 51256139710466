import { ChangeParamsDialog } from "@/features/params/components/ChangeParamsDialog";
import { removeAllClientToChangeParams } from "@/features/params/store/clientSelectedToChangeParamsSlice";
import { getHistoryByUser } from "@/features/params/store/historyOfParamsSlice";
import { selectSelectedSalesmanToChangeClientParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Params } from "@core/models/params.model";
import { TimelineItemOfParams } from "@features/history/components/TimelineItemOfParams";
import {
  Flex,
  IconButton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface TimelineListProps {
  params: Params[];
}

export const TimelineListOfParams = ({ params }: TimelineListProps) => {
  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeClientParams
  );

  const { client_id } = useParams();
  const dispatch = useAppDispatch();

  const handleSetClientToChangeParams = async () => {
    await dispatch(removeAllClientToChangeParams());
  };

  const handleUpdateParams = async () => {
    await dispatch(
      getHistoryByUser({
        client_id: client_id,
        user_id: selectedSalesmanToChangeParams.id,
      })
    );
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
        marginTop: "-12px",
      }}
    >
      <Flex
        css={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 $3",
        }}
      >
        <ChangeParamsDialog onUpdateParams={handleUpdateParams}>
          <Text
            css={{
              color: "$primary500",
              textAlign: "left",
              width: "fit-content",
              textDecoration: "underline",
            }}
            onClick={handleSetClientToChangeParams}
          >
            Editar parâmetros do cliente
          </Text>
        </ChangeParamsDialog>

        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <IconButton
              onClick={handleUpdateParams}
              type="soft"
              color="gray"
              bordered
            >
              <MdRefresh />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent aria-label="Atualizar Histórico">
            Atualizar Histórico
          </TooltipContent>
        </Tooltip>
      </Flex>

      <div>
        {params.map((hist, idx) => {
          return <TimelineItemOfParams params={hist} key={idx} />;
        })}
      </div>
    </Flex>
  );
};
