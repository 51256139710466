import { Input } from "@gogeo-io/ui-library";
import { FieldError } from "react-hook-form";

interface FormInputFieldProps {
  type: "text" | "number";
  placeholder: string;
  error?: FieldError;
  field: {
    [key: string]: any;
  };
}

export function FormInputField({
  placeholder,
  error,
  field,
  type,
}: FormInputFieldProps) {
  return (
    <Input
      placeholder={placeholder}
      type={type}
      isFullWidth
      hasError={!!error}
      value={field.value}
      errorMessage={error?.message}
      min={0}
      {...field}
    />
  );
}
