import { User } from "@/core/models/user.model";
import { ChangeParamsOfOneSalesmanForm } from "@/features/params/components/ParamsOfSalesmanForm/ChangeParamsOfOneSalesmanForm";
import {
  addSelectedSalesmanToChangeParams,
  removeSelectedSalesmanToChangeParams,
  selectSelectedSalesmansToChangeParams,
} from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  IconButton,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdClose, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { ListDivider } from "../ListDivider";

interface EditParamsOfSalesmanProps {
  user: User;
  onLoadSalesmansRelatedToUser: () => Promise<void>;
}

export function EditParamsOfSalesman({
  user,
  onLoadSalesmansRelatedToUser,
}: EditParamsOfSalesmanProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();

  const handleSetSelectedSalesmanToChangeParams = async () => {
    await dispatch(addSelectedSalesmanToChangeParams(user));
  };

  const handleOnOpenChange = async (value: boolean) => {
    if (!value) {
      await dispatch(removeSelectedSalesmanToChangeParams(user));
    }
    setDialogIsOpen(value);
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleOnOpenChange}>
      <DialogTrigger onClick={handleSetSelectedSalesmanToChangeParams}>
        <IconButton type="ghost" bordered size="small">
          <MdEdit />
        </IconButton>
      </DialogTrigger>
      <DialogContent style={{ maxHeight: "90vh" }}>
        <>
          <DialogClose>
            <IconButton
              size="small"
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <MdClose fontSize="inherit" />
            </IconButton>
          </DialogClose>
          <DialogTitle>
            Alterar parâmetros de atendimento do vendedor
          </DialogTitle>

          <ListDivider />

          <ChangeParamsOfOneSalesmanForm
            salesman={selectedSalesmansToChangeParams[0]}
            onLoadSalesmansRelatedToUser={onLoadSalesmansRelatedToUser}
            onCloseDialog={() => setDialogIsOpen(false)}
          />
        </>
      </DialogContent>
    </Dialog>
  );
}
