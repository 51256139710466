import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { FormLabel } from "./FormLabel";
import { FormRadioBooleanField } from "./FormRadioBooleanField";

interface FormControllerRadioProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  showNoChangeOption?: boolean;
  additionalOptions?: { title: string; value: boolean }[];
}

export function FormControllerRadioInvertedValue<T extends FieldValues>({
  control,
  name,
  label,
  showNoChangeOption = false,
  additionalOptions,
}: FormControllerRadioProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <FormLabel label={label} />
          <FormRadioBooleanField
            value={value}
            onChange={onChange}
            options={[
              ...(showNoChangeOption
                ? [{ title: "Não alterar", value: undefined }]
                : []),
              { title: "Sim", value: false },
              { title: "Não", value: true },
              ...(additionalOptions || []),
            ]}
          />
          <G_UI.ErrorMessage>{!!error && error.message}</G_UI.ErrorMessage>
        </Flex>
      )}
    />
  );
}
