import { ErrorContent } from "@/components/ErrorContent";
import { ListDivider } from "@/components/ListDivider";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { ClientParamsResponse } from "@/core/models/client.model";
import { ListOfTagsFilter, SortOrder } from "@/core/models/listOfTags.model";
import {
  emitEventToChangeClients,
  selectStatusOfEmitEventToChangeClients,
} from "@/features/attendance/store/emitEventToChangeClientsSlice";
import {
  selectColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import {
  getClientsByFilters,
  getClientsCount,
  selectClientsHasError500,
} from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Box, Flex } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParamsColumns } from "../hooks/useParamsColumns";
import { selectSelectedSalesmanToChangeClientParams } from "../store/paramsSlice";
import { HeaderPageWithSalesmanToSelect } from "./HeaderPageWithSalesmanToSelect";
import { ParamsClientsTable } from "./ParamsClientsTable";
import { SearchClientsOnParamsPage } from "./SearchClientsOnParamsPage";

export function ParamsClients() {
  const [hasMorePages, setHasMorePages] = useState(true);
  const [clients, setClients] = useState<ClientParamsResponse[]>([]);
  const [clientStatus, setClientsStatus] = useState("loading");
  const [loadedPage, setLoadedPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const selectedColumnsToSort = useSelector(selectColumnsToSort);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );
  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeClientParams
  );
  const hasError500 = useSelector(selectClientsHasError500);

  const dispatch = useAppDispatch();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetSort = async (
    direction: "ASC" | "DESC" | "",
    property: string
  ) => {
    await dispatch(emitEventToChangeClients(false));

    const newColumn: SortOrder = { direction, index: 0, property };

    if (selectedColumnsToSort.length === 0) {
      await dispatch(setColumnsToSort([newColumn]));
    } else {
      const findedColumn = selectedColumnsToSort.find(
        (selectedColum) => selectedColum.property === newColumn.property
      );

      let columnsWithNewColumn: SortOrder[] = [];

      if (isUndefined(findedColumn)) {
        columnsWithNewColumn = [newColumn, ...selectedColumnsToSort];
      } else {
        const columnsWithoutRemovedOne = selectedColumnsToSort.filter(
          (selectedColum) => selectedColum.property !== newColumn.property
        );
        columnsWithNewColumn = [newColumn, ...columnsWithoutRemovedOne];
      }

      const columnsWithoutEmptyDirection = columnsWithNewColumn.filter(
        (selectedColum) => selectedColum.direction !== ""
      );

      const newOrderOfColumns = columnsWithoutEmptyDirection.map(
        (col, idx) => col && { ...col, index: idx }
      );

      await dispatch(setColumnsToSort(newOrderOfColumns));
    }

    await dispatch(emitEventToChangeClients(true));
  };

  const loadClients = async () => {
    setCurrentPage(0);
    setHasMorePages(true);
    setClientsStatus("loading");

    let params: ListOfTagsFilter = {};

    params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
        bringParams: true,
        user_id: selectedSalesmanToChangeParams.id,
      })
    ).then(async (res) => {
      if (res.payload.clients.length < import.meta.env.VITE_PAGE_SIZE) {
        setHasMorePages(false);
        setClients(res.payload.clients);
      } else {
        setClients(res.payload.clients);
      }

      setClientsStatus("fulfilled");
      setLoadedPage(true);
    });
    await dispatch(
      getClientsCount({
        params,
        user_id: selectedSalesmanToChangeParams.id,
      })
    );
  };

  useEffect(() => {
    if (statusOfEmitEventToChangeClients) {
      loadClients();
    }
  }, [statusOfEmitEventToChangeClients]);

  const getPaginatedClients = async (currentPage: number) => {
    let params: ListOfTagsFilter = {};

    params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
        bringParams: true,
        user_id: selectedSalesmanToChangeParams.id,
      })
    ).then((res) => {
      if (res.payload.clients.length < import.meta.env.VITE_PAGE_SIZE) {
        setHasMorePages(false);
      } else {
        setClients((prevClients) => [...prevClients, ...res.payload.clients]);
      }
    });
  };

  const clientsParamsColumns = useParamsColumns(handleSetSort);

  if (hasError500) {
    return (
      <ErrorContent size="medium">
        Não conseguimos buscar os clientes de sua lista, tente novamente mais
        tarde ou atualize a página
      </ErrorContent>
    );
  }

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <HeaderPageWithSalesmanToSelect
        title="Parâmetros de atendimento dos clientes"
        description="Selecione um cliente e altere seus parâmetros"
      />

      <ListDivider />

      <SearchClientsOnParamsPage showAssociatedUsersFilter={false} />

      <Box css={{ height: "100%" }}>
        {loadedPage ? (
          <ParamsClientsTable
            columns={clientsParamsColumns}
            data={clients}
            onPageChange={getPaginatedClients}
            currentPage={currentPage}
            onIncreasePageNumber={handleIncreasePageNumber}
            hasMorePages={hasMorePages}
            status={clientStatus}
          />
        ) : (
          <ClientsTableSkeletonPage />
        )}
      </Box>
    </Flex>
  );
}
