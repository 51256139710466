import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { ShowPreferedDays } from "@/components/PreferedDays/ShowPreferedDays";
import { PreferedDaysAndWeeksSubtitle } from "@/components/PreferedDaysAndWeeksSubtitle";
import { LastHistoryOfParamsByUser } from "@/core/models/params.model";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useMemo } from "react";
import { TimelineObservationsCell } from "../components/TimelineObservationsCell";

export const useHistoryParamsByUsersColumns = () => {
  const {
    formatHourAndMinutes,
    formatBasicDateWithSlash,
    newDateBasedOnDateWithoutTimezone,
  } = useFormatDate();

  const columns = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Vendedor" />,
        dataIndex: "user",
        key: "user",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.user.name;
          return <Text css={{ textAlign: "center" }}>{value}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Parametrizado por" />,
        dataIndex: "params.generated_by",
        key: "params.generated_by",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.generated_by;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Do grupo" />,
        dataIndex: "params.group",
        key: "params.group",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.group;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Data de alteração" />,
        dataIndex: "params.date",
        key: "params.date",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.date;
          return (
            <Text css={{ textAlign: "center" }}>
              {!isEmpty(value) ? (
                <>
                  {formatBasicDateWithSlash(
                    newDateBasedOnDateWithoutTimezone(value)
                  )}
                  &nbsp;às&nbsp;
                  {formatHourAndMinutes(new Date(value))}
                </>
              ) : (
                "-"
              )}
            </Text>
          );
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Frequência de atendimento en dias" />
        ),
        dataIndex: "params.freq",
        key: "params.freq",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.freq;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Semana de atendimento no mês" />
        ),
        dataIndex: "params.week_pref",
        key: "params.week_pref",
        render: (_, record: LastHistoryOfParamsByUser) => {
          return (
            <Text css={{ textAlign: "center" }}>
              {!isEmpty(record) && !isEmpty(record.params.week_pref) ? (
                <>{` ${record.params.week_pref.range} ${record.params.week_pref.value}`}</>
              ) : (
                " Não Possui"
              )}
            </Text>
          );
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Gerar agenda" />,
        dataIndex: "params.non_schedulable",
        key: "params.non_schedulable",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params.non_schedulable;
          return (
            <Text css={{ textAlign: "center" }}>
              {!isUndefined(value) ? (value ? "Não" : "Sim") : "-"}
            </Text>
          );
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Dias preferidos no mês" />,
        dataIndex: "params.days_week_pref",
        key: "params.days_week_pref",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.days_week_pref;
          return (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <ShowPreferedDays isBlocked daysWeekPrefReceived={value} />
              </TooltipTrigger>
              <TooltipContent aria-label="Legenda dos dias preferidos">
                <PreferedDaysAndWeeksSubtitle isOnTooltip />
              </TooltipContent>
            </Tooltip>
          );
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Observações" />,
        dataIndex: "params.observations",
        key: "params.observations",
        render: (_, record: LastHistoryOfParamsByUser) => {
          const value = record.params?.observations;
          return <TimelineObservationsCell observation={value} />;
        },
      },
    ];
  }, []);

  return [...columns];
};
