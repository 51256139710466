import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { clientMock } from "@/core/mocks/clientMock";
import { ClientParamsResponse } from "@/core/models/client.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ClientState {
  has500error: boolean;
  data: ClientParamsResponse;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  data: {},
  status: "loading",
} as ClientState;

interface GetClientAndParamsBodyProps {
  client_id: string;
  user_id?: number;
  shouldBringParams: boolean;
}

export const getClientById = createAsyncThunk(
  "client/getClientById",
  async (
    { client_id, user_id, shouldBringParams }: GetClientAndParamsBodyProps,
    thunkAPI
  ) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return clientMock;
    } else {
      // await sleep(4000);
      try {
        let url = "";
        if (user_id) {
          url = `/clients/info/${client_id}?params=${shouldBringParams}&userId=${user_id}`;
        } else {
          url = `/clients/info/${client_id}?params=${shouldBringParams}`;
        }
        const response = await axios.get(url);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const clientSlice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientById.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getClientById.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(
      getClientById.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the client info");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectClient = (state: RootState) => state.clientState.data.client;
export const selectClientWithParams = (state: RootState) =>
  state.clientState.data;
export const selectClientStatus = (state: RootState) =>
  state.clientState.status;
export const selectClientParams = (state: RootState) =>
  state.clientState.data.params;
export const selectClientHas500error = (state: RootState) =>
  state.clientState.has500error;

export const clientReducer = clientSlice.reducer;
