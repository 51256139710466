import { User } from "@/core/models/user.model";
import { selectSalesmansRelatedToUser } from "@/core/store/users/usersSlice";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Caption,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { ReactElement, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { removeAllClientToChangeParams } from "../store/clientSelectedToChangeParamsSlice";
import {
  changeParams,
  selectSelectedSalesmanToChangeClientParams,
  setSelectedSalesmanToChangeClientParams,
} from "../store/paramsSlice";

interface SelectSalesmanToGetChangeParamsProps {
  children: ReactElement;
}

export function SelectSalesmanToGetChangeParams({
  children,
}: SelectSalesmanToGetChangeParamsProps) {
  const salesmansRelatedToUser = useSelector(selectSalesmansRelatedToUser);
  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeClientParams
  );

  const [search, setSearch] = useState("");
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [filteredSalesmans, setFilteredSalesmans] = useState<User[]>([]);

  const dispatch = useAppDispatch();

  const handleInputChange = (value: string) => {
    const searchTerm = value.toLowerCase();
    setSearch(searchTerm);
  };

  const handleSelectedSalesmanChange = async (salesman: User) => {
    await dispatch(emitEventToChangeClients(false));
    const findedSalesman = salesmansRelatedToUser.find(
      (user) => salesman.active && user.id === salesman.id
    );
    await dispatch(setSelectedSalesmanToChangeClientParams(findedSalesman));
    setSearch("");
    await dispatch(removeAllClientToChangeParams());
    await dispatch(changeParams({ params: "cleanAllFilters" }));
    setPopoverIsOpen(false);
    await dispatch(emitEventToChangeClients(true));
  };

  useEffect(() => {
    setFilteredSalesmans(salesmansRelatedToUser);
  }, []);

  useEffect(() => {
    const filteredSalesmen = salesmansRelatedToUser.filter(
      (salesman) =>
        salesman.active && salesman.name.toLowerCase().includes(search)
    );
    setFilteredSalesmans(filteredSalesmen);
  }, [search]);

  return (
    <Popover open={popoverIsOpen} onOpenChange={setPopoverIsOpen}>
      <PopoverTrigger
        style={{ background: "transparent", width: "fit-content" }}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverClose asChild={true}>
          <IconButton
            type="ghost"
            css={{
              position: "absolute",
              top: "0.25rem",
              right: "0rem",
              cursor: "pointer",
            }}
            onClick={() => setSearch("")}
          >
            <MdClose />
          </IconButton>
        </PopoverClose>

        <Flex css={{ flexDirection: "column", gap: "$4" }}>
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Caption>Procurar vendedor</Caption>
            <Input
              placeholder="Ex. Vendedor 4"
              value={search}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </Flex>
          <Box css={{ maxHeight: "500px", overflowY: "scroll" }}>
            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              {filteredSalesmans
                .filter((salesman) => salesman.active)
                .map((salesman) => {
                  return (
                    <SelectUser
                      key={salesman.id}
                      onClick={() => handleSelectedSalesmanChange(salesman)}
                      className={
                        salesman.id === selectedSalesmanToChangeParams.id
                          ? "selected"
                          : ""
                      }
                    >
                      <Text>{salesman.name}</Text>
                    </SelectUser>
                  );
                })}
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Caption css={{ color: "$gray700", paddingTop: "$3" }}>
            * Os vendedores inativos não aparecem para seleção
          </Caption>
        </Flex>
      </PopoverContent>
    </Popover>
  );
}

export const SelectUser = styled.div`
  padding: 0.75rem;
  cursor: pointer;
  border-radius: ${(props) => props.theme.sizes[1]};

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }

  &.selected {
    background: ${(props) => props.theme.colors.gray100};
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;
