import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { LastHistoryOfParamsByUser, Params } from "@/core/models/params.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ParamsState {
  lastHistoryOfParamsByUsers: LastHistoryOfParamsByUser[];
  historyByUser: Params[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  historyByUser: [],
  lastHistoryOfParamsByUsers: [],
  status: "loading",
} as ParamsState;

interface GetLastHistoryOfParamsByUsersProps {
  client_id: string;
}

export const getLastHistoryOfParamsByUsers = createAsyncThunk(
  "historyOfParams/getHistoryOfParams",
  async (body: GetLastHistoryOfParamsByUsersProps, thunkAPI) => {
    try {
      const response = await axios.get(
        `/user/parameters?clientId=${body.client_id}`
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetHistoryByUserProps {
  client_id: string;
  user_id: number;
}

export const getHistoryByUser = createAsyncThunk(
  "historyOfParams/getHistoryByUser",
  async (body: GetHistoryByUserProps, thunkAPI) => {
    try {
      const response = await axios.get(
        `/clients/info/history/${body.client_id}?userId=${body.user_id}`
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const historyOfParamsSlice = createSlice({
  name: "paramsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLastHistoryOfParamsByUsers.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      getLastHistoryOfParamsByUsers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.lastHistoryOfParamsByUsers = action.payload;
      }
    );
    builder.addCase(
      getLastHistoryOfParamsByUsers.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on getLastHistoryOfParamsByUsers");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getHistoryByUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      getHistoryByUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.historyByUser = action.payload;
      }
    );
    builder.addCase(
      getHistoryByUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on getHistoryByUser");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectHistorByUser = (state: RootState) =>
  state.historyOfparamsState.historyByUser;
export const selectLastHistoryOfParamsByUsers = (state: RootState) =>
  state.historyOfparamsState.lastHistoryOfParamsByUsers;
export const selectHistoryOfParamsStatus = (state: RootState) =>
  state.historyOfparamsState.status;

export const historyOfparamsReducer = historyOfParamsSlice.reducer;
