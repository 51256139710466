import { FormControllerInputWithUndefined } from "@/components/form/FormControllerInputWithUndefined";
import { FormControllerRadioInvertedValue } from "@/components/form/FormControllerRadioInvertedValue";
import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { FormRadioBooleanField } from "@/components/form/FormRadioBooleanField";
import { useProfileMixpanel } from "@/features/profile/hooks/useProfileMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Button,
  DialogClose,
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  gvTheme,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RiInformationLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  ParamsOfSalesmanFormData,
  paramsOfSalesmanFormSchema,
} from "../../hooks/paramsOfSalesmanFormSchema";
import {
  selectSelectedSalesmansToChangeParams,
  setSelectedSalesmansToChangeParams,
  updateUserParams,
} from "../../store/paramsSlice";
import { DaysWithRuptureFormField } from "../DaysWithRuptureFormField";
import { CountOfSalesmansToChangeParams } from "./CountOfSalesmansToChangeParams";

interface ParamsOfSalesmanFormProps {
  onLoadSalesmansRelatedToUser: () => Promise<void>;
  onCloseDialog: () => void;
}

export function ParamsOfSalesmanForm({
  onLoadSalesmansRelatedToUser,
  onCloseDialog,
}: ParamsOfSalesmanFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();
  const { changeParamsOfSalesmanEvent } = useProfileMixpanel();

  const { handleSubmit, control, watch } = useForm<ParamsOfSalesmanFormData>({
    mode: "onBlur",
    resolver: yupResolver(paramsOfSalesmanFormSchema),
  });

  const onSubmit: SubmitHandler<ParamsOfSalesmanFormData> = async (
    data: ParamsOfSalesmanFormData
  ): Promise<void> => {
    setIsLoading(true);

    interface BodyProps {
      user_ids: number[];
      params: ParamsOfSalesmanFormData;
    }

    const body: BodyProps = {
      user_ids: selectedSalesmansToChangeParams.map((salesman) => salesman.id),
      params: {
        ...data,
      },
    };

    await dispatch(updateUserParams(body)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        onLoadSalesmansRelatedToUser().then(async () => {
          onCloseDialog();
          await dispatch(setSelectedSalesmansToChangeParams([]));
          changeParamsOfSalesmanEvent(body.user_ids, body.params);
          toast.success("Parâmetros alterados com successo");
        });
      }
    });

    setIsLoading(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar os parâmetros dos vendedores");
    }
  };

  return (
    <Container>
      <FormContainer>
        <CountOfSalesmansToChangeParams />

        <FormControllerRadioInvertedValue
          control={control}
          label="Permitir bloqueados"
          name="ignore_blocked"
          showNoChangeOption
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Dias sem compras para inativos"
          name="inactive_days"
          defaultValue={30}
          placeholder="Ex. 90"
          type="number"
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Dias de trabalho por mês"
          name="meta_parameters.work_metrics.work_days_per_month"
          defaultValue={22}
          placeholder="Ex. 22"
          type="number"
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Horas trabalhadas por dia"
          name="meta_parameters.work_metrics.work_hours_per_day"
          defaultValue={8}
          placeholder="Ex. 8"
          type="number"
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Horas úteis de trabalho"
          name="meta_parameters.work_metrics.effective_work_hours"
          defaultValue={7}
          placeholder="Ex. 7"
          type="number"
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Tempo médio de atendimento (em minutos)"
          name="meta_parameters.work_metrics.average_attendance_time"
          defaultValue={15}
          placeholder="Ex. 15"
          type="number"
        />

        <Controller
          control={control}
          name={"meta_parameters.work_metrics.max_visit"}
          render={({ field, fieldState: { error } }) => {
            const [isUndefined, setIsUndefined] = useState(true);

            const handleRadioChange = (value) => {
              setIsUndefined(value === undefined);
              if (value === undefined) {
                field.onChange(undefined);
              } else {
                field.onChange(25); //default value
              }
            };

            const effective_work_hours = watch(
              "meta_parameters.work_metrics.effective_work_hours"
            );
            const average_attendance_time = watch(
              "meta_parameters.work_metrics.average_attendance_time"
            );

            const showSuggestion =
              average_attendance_time && effective_work_hours;
            let suggestion = 0;

            if (String(average_attendance_time) !== "0") {
              suggestion = Math.floor(
                (effective_work_hours * 60) / average_attendance_time
              );
            } else {
              suggestion = 0;
            }

            return (
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <FormLabel label="Capacidade de clientes por dia" />

                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <FormRadioBooleanField
                    onChange={handleRadioChange}
                    value={undefined}
                    options={[
                      { title: "Não alterar", value: undefined },
                      { title: "Definir valor", value: true },
                    ]}
                  />

                  <Flex css={{ alignItems: "center", gap: "$2" }}>
                    {!isUndefined && (
                      <Box css={{ width: "220px" }}>
                        <FormInputField
                          placeholder="Ex. 25"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Box>
                    )}
                    {showSuggestion && !isUndefined && (
                      <Flex css={{ alignItems: "center", gap: "$1" }}>
                        <Text>Sugestão: {suggestion} clientes por dia</Text>
                        <Tooltip>
                          <TooltipTrigger style={{ background: "transparent" }}>
                            <RiInformationLine color={gvTheme.colors.gray700} />
                          </TooltipTrigger>
                          <TooltipContent
                            style={{ zIndex: "9999" }}
                            aria-label="Selecione ao menos um usuário para alterar os parâmetros de atendimento"
                          >
                            Esse valor foi sugerido pelo govendas atráves de
                            análise de suas configurações
                          </TooltipContent>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Frequência de clientes ativos"
          name="meta_parameters.active_freq"
          defaultValue={7}
          placeholder="Ex. 7"
          type="number"
        />

        <FormControllerInputWithUndefined
          control={control}
          label="Frequência de clientes inativos"
          name="meta_parameters.inactive_freq"
          defaultValue={14}
          placeholder="Ex. 14"
          type="number"
        />

        <FormControllerRadioInvertedValue
          control={control}
          label="Agendar inadimplentes"
          name="meta_parameters.skip_defaulting"
          showNoChangeOption
        />

        <Flex css={{ flexDirection: "column", gap: "$3" }}>
          <FormControllerInputWithUndefined
            control={control}
            label="Frequência de clientes em ruptura"
            name="meta_parameters.rupture_params.freq"
            defaultValue={7}
            placeholder="Ex. 7"
            type="number"
          />

          <Controller
            control={control}
            name="meta_parameters.rupture_params"
            render={({ field }) => {
              const [isUndefined, setIsUndefined] = useState(true);

              const handleRadioChange = (value) => {
                setIsUndefined(value === undefined);
                const resetValues = {
                  mon_pref: undefined,
                  tue_pref: undefined,
                  wed_pref: undefined,
                  thu_pref: undefined,
                  fri_pref: undefined,
                  sat_pref: undefined,
                  sun_pref: undefined,
                };

                field.onChange(resetValues); //default values
              };

              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Dias com agenda de ruptura" />

                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <FormRadioBooleanField
                      onChange={handleRadioChange}
                      value={undefined}
                      options={[
                        { title: "Não alterar", value: undefined },
                        { title: "Definir valor", value: true },
                      ]}
                    />

                    {!isUndefined && (
                      <DaysWithRuptureFormField
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  </Flex>
                </Flex>
              );
            }}
          />
        </Flex>
      </FormContainer>

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
          mt: "$4",
        }}
      >
        <DialogClose style={{ background: "none" }}>
          <Button bordered>Cancelar</Button>
        </DialogClose>
        <Button
          isLoading={isLoading}
          disabled={selectedSalesmansToChangeParams.length === 0}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Salvar
        </Button>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
  margin-top: ${(props) => props.theme.space[2]};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  padding: 4px;
  max-height: 500px;
  overflow-y: scroll;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 380px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 300px;
  }
`;
