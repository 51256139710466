import * as yup from "yup";

export interface ParamsOfSalesmanFormData {
  ignore_blocked: boolean;
  inactive_days: number;
  max_visit: number;
  meta_parameters: {
    active_freq: number;
    inactive_freq: number;
    skip_defaulting: boolean;
    work_metrics: {
      work_days_per_month: number;
      work_hours_per_day: number;
      effective_work_hours: number;
      average_attendance_time: number;
      max_visit: number;
    };
    rupture_params: {
      freq: number;
      mon_pref: number;
      tue_pref: number;
      wed_pref: number;
      thu_pref: number;
      fri_pref: number;
      sat_pref: number;
      sun_pref: number;
    };
  };
}

export const paramsOfSalesmanFormSchema = yup.object().shape({
  ignore_blocked: yup.boolean(),
  inactive_days: yup
    .number()
    .min(30, "Deve ser maior que 30 dias")
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  meta_parameters: yup.object().shape({
    active_freq: yup
      .number()
      .notRequired()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    inactive_freq: yup
      .number()
      .notRequired()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    skip_defaulting: yup.boolean(),
    work_metrics: yup.object().shape({
      max_visit: yup
        .number()
        .min(1, "Deve ser maior que 1 cliente/dia")
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      work_days_per_month: yup
        .number()
        .min(1, "Deve trabalhar pelo menos 1 dia por mês")
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      work_hours_per_day: yup
        .number()
        .min(1, "Deve trabalhar pelo menos 1 hora por dia")
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      effective_work_hours: yup
        .number()
        .min(1, "Deve trabalhar pelo menos 1 hora por dia")
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      average_attendance_time: yup
        .number()
        .min(1, "Tempo médio de atendimento deve ser maior que 1 minuto")
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
    }),
    rupture_params: yup.object().shape({
      freq: yup
        .number()
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      mon_pref: yup.number().oneOf([0, 1]),
      tue_pref: yup.number().oneOf([0, 1]),
      wed_pref: yup.number().oneOf([0, 1]),
      thu_pref: yup.number().oneOf([0, 1]),
      fri_pref: yup.number().oneOf([0, 1]),
      sat_pref: yup.number().oneOf([0, 1]),
      sun_pref: yup.number().oneOf([0, 1]),
    }),
  }),
});
