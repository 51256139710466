export const useTokenizeString = () => {
  /*
   * Entrada: " João da Silva "
   * Após .normalize: "Joao da Silva"
   * Após .replace: "Joao da Silva"
   * Após .toLowerCase: "joao da silva"
   * Após .split: ["joao", "da", "silva"]
   * Após .filter: ["joao", "da", "silva"]
   */
  const tokenize = (str: string): string[] => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter(Boolean); // Remove espaços extras
  };

  return { tokenize };
};
