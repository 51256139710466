import { User } from "@/core/models/user.model";
import { UseFormSetValue } from "react-hook-form";
import { ParamsOfSalesmanFormData } from "./paramsOfSalesmanFormSchema";

export const useSetValuesOnParamsOfSalesmanForm = () => {
  const setValuesOnForm = async (
    setValue: UseFormSetValue<ParamsOfSalesmanFormData>,
    salesman: User
  ) => {
    setValue("ignore_blocked", salesman?.ignore_blocked);
    setValue("inactive_days", salesman?.inactive_days);
    setValue(
      "meta_parameters.active_freq",
      salesman?.meta_parameters?.active_freq
    );
    setValue(
      "meta_parameters.inactive_freq",
      salesman?.meta_parameters?.inactive_freq
    );
    setValue(
      "meta_parameters.skip_defaulting",
      salesman?.meta_parameters?.skip_defaulting
    );
    setValue(
      "meta_parameters.rupture_params.freq",
      salesman?.meta_parameters?.rupture_params.freq
    );
    setValue(
      "meta_parameters.rupture_params.mon_pref",
      salesman?.meta_parameters?.rupture_params.mon_pref
    );
    setValue(
      "meta_parameters.rupture_params.tue_pref",
      salesman?.meta_parameters?.rupture_params.tue_pref
    );
    setValue(
      "meta_parameters.rupture_params.wed_pref",
      salesman?.meta_parameters?.rupture_params.wed_pref
    );
    setValue(
      "meta_parameters.rupture_params.thu_pref",
      salesman?.meta_parameters?.rupture_params.thu_pref
    );
    setValue(
      "meta_parameters.rupture_params.fri_pref",
      salesman?.meta_parameters?.rupture_params.fri_pref
    );
    setValue(
      "meta_parameters.rupture_params.sat_pref",
      salesman?.meta_parameters?.rupture_params.sat_pref
    );
    setValue(
      "meta_parameters.rupture_params.sun_pref",
      salesman?.meta_parameters?.rupture_params.sun_pref
    );
    setValue(
      "meta_parameters.work_metrics.average_attendance_time",
      salesman?.meta_parameters?.work_metrics?.average_attendance_time
    );
    setValue(
      "meta_parameters.work_metrics.effective_work_hours",
      salesman?.meta_parameters?.work_metrics?.effective_work_hours
    );
    setValue(
      "meta_parameters.work_metrics.max_visit",
      salesman?.meta_parameters?.work_metrics?.max_visit
    );
    setValue(
      "meta_parameters.work_metrics.work_days_per_month",
      salesman?.meta_parameters?.work_metrics?.work_days_per_month
    );
    setValue(
      "meta_parameters.work_metrics.work_hours_per_day",
      salesman?.meta_parameters?.work_metrics?.work_hours_per_day
    );
  };

  const setDefaultValues = (salesman: User) => {
    return {
      ignore_blocked: salesman?.ignore_blocked,
      inactive_days: salesman?.inactive_days,
      meta_parameters: {
        active_freq: salesman?.meta_parameters?.active_freq,
        inactive_freq: salesman?.meta_parameters?.inactive_freq,
        skip_defaulting: salesman?.meta_parameters?.skip_defaulting,
        work_metrics: {
          max_visit: salesman?.meta_parameters?.work_metrics?.max_visit,
          average_attendance_time:
            salesman?.meta_parameters?.work_metrics?.average_attendance_time,
          effective_work_hours:
            salesman?.meta_parameters?.work_metrics?.effective_work_hours,
          work_days_per_month:
            salesman?.meta_parameters?.work_metrics?.work_days_per_month,
          work_hours_per_day:
            salesman?.meta_parameters?.work_metrics?.work_hours_per_day,
        },
        rupture_params: {
          freq: salesman?.meta_parameters?.rupture_params?.freq,
          mon_pref: salesman?.meta_parameters?.rupture_params?.mon_pref,
          tue_pref: salesman?.meta_parameters?.rupture_params?.tue_pref,
          wed_pref: salesman?.meta_parameters?.rupture_params?.wed_pref,
          thu_pref: salesman?.meta_parameters?.rupture_params?.thu_pref,
          fri_pref: salesman?.meta_parameters?.rupture_params?.fri_pref,
          sat_pref: salesman?.meta_parameters?.rupture_params?.sat_pref,
          sun_pref: salesman?.meta_parameters?.rupture_params?.sun_pref,
        },
      },
    };
  };

  return {
    setValuesOnForm,
    setDefaultValues,
  };
};
