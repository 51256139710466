import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { EditParamsOfSalesman } from "@/components/CustomCells/EditParamsOfSalesman";
import { SelectSalesmanToChangeParams } from "@/components/CustomCells/SelectSalesmanToChangeParams";
import { User } from "@/core/models/user.model";
import { Text } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useMemo } from "react";
import { DaysWithRuptureFormField } from "../components/DaysWithRuptureFormField";
import { SelectAllSalesmansToChangeParamsCheckbox } from "../components/SelectAllSalesmansToChangeParamsCheckbox";
import { UserOnParamsOfSalesmanCell } from "../components/UserOnParamsOfSalesmanCell";

export const useParamsOfSalesmansColumns = (
  onLoadSalesmansRelatedToUser: () => Promise<void>
) => {
  const columns = useMemo(() => {
    return [
      {
        title: () => <SelectAllSalesmansToChangeParamsCheckbox />,
        accessor: "user",
        key: "user",
        render: (_, record: User) => {
          return <SelectSalesmanToChangeParams user={record} />;
        },
        fixed: "left",
      },
      {
        title: <CellHeaderWithoutSort headerName="Vendedor" />,
        dataIndex: "name",
        key: "name",
        render: (_, record: User) => {
          return <UserOnParamsOfSalesmanCell user={record} />;
        },
        fixed: "left",
      },
      {
        title: <CellHeaderWithoutSort headerName="Permitir bloqueados" />,
        width: 100,
        dataIndex: "ignore_blocked",
        key: "ignore_blocked",
        render: (_, record: User) => {
          const value = record.ignore_blocked;
          return (
            <Text css={{ textAlign: "center" }}>
              {!isUndefined(value) ? (value ? "Não" : "Sim") : "-"}
            </Text>
          );
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Dias sem compras para inativos" />
        ),
        width: 100,
        dataIndex: "inactive_days",
        key: "inactive_days",
        render: (_, record: User) => {
          const value = record.inactive_days;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Capacidade de clientes por dia" />
        ),
        width: 100,
        dataIndex: "meta_parameters.work_metrics.max_visit",
        key: "meta_parameters.work_metrics.max_visit",
        render: (_, record: User) => {
          const value = record.meta_parameters?.work_metrics?.max_visit;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Dias de trabalho por mês" />,
        width: 100,
        dataIndex: "meta_parameters.work_metrics.work_days_per_month",
        key: "meta_parameters.work_metrics.work_days_per_month",
        render: (_, record: User) => {
          const value =
            record.meta_parameters?.work_metrics?.work_days_per_month;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Horas trabalhadas por dia" />,
        width: 100,
        dataIndex: "meta_parameters.work_metrics.work_hours_per_day",
        key: "meta_parameters.work_metrics.work_hours_per_day",
        render: (_, record: User) => {
          const value =
            record.meta_parameters?.work_metrics?.work_hours_per_day;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Horas úteis de trabalho" />,
        width: 100,
        dataIndex: "meta_parameters.work_metrics.effective_work_hours",
        key: "meta_parameters.work_metrics.effective_work_hours",
        render: (_, record: User) => {
          const value =
            record.meta_parameters?.work_metrics?.effective_work_hours;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Tempo médio de atendimento (em minutos)" />
        ),
        width: 100,
        dataIndex: "meta_parameters.work_metrics.average_attendance_time",
        key: "meta_parameters.work_metrics.average_attendance_time",
        render: (_, record: User) => {
          const value =
            record.meta_parameters?.work_metrics?.average_attendance_time;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Frequência de clientes ativos" />
        ),
        width: 100,
        dataIndex: "meta_parameters.active_freq",
        key: "meta_parameters.active_freq",
        render: (_, record: User) => {
          const value = record.meta_parameters?.active_freq;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Frequência de clientes inativos" />
        ),
        width: 100,
        dataIndex: "meta_parameters.inactive_freq",
        key: "meta_parameters.inactive_freq",
        render: (_, record: User) => {
          const value = record.meta_parameters?.inactive_freq;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: <CellHeaderWithoutSort headerName="Agendar inadimplentes" />,
        width: 115,
        dataIndex: "meta_parameters.skip_defaulting",
        key: "meta_parameters.skip_defaulting",
        render: (_, record: User) => {
          const value = record.meta_parameters?.skip_defaulting;
          return (
            <Text css={{ textAlign: "center" }}>
              {!isUndefined(value) ? (value ? "Não" : "Sim") : "-"}
            </Text>
          );
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Frequência de clientes em ruptura" />
        ),
        width: 100,
        dataIndex: "meta_parameters?.rupture_params?.freq",
        key: "meta_parameters?.rupture_params?.freq",
        render: (_, record: User) => {
          const value = record.meta_parameters?.rupture_params?.freq;
          return <Text css={{ textAlign: "center" }}>{value ?? "-"}</Text>;
        },
      },
      {
        title: (
          <CellHeaderWithoutSort headerName="Dias com agenda de ruptura" />
        ),
        dataIndex: "meta_parameters?.rupture_params",
        key: "meta_parameters?.rupture_params",
        render: (_, record: User) => {
          return (
            <DaysWithRuptureFormField
              value={record.meta_parameters?.rupture_params}
              onChange={() => {}}
              disabledToChange
            />
          );
        },
      },
      {
        title: () => <CellHeaderWithoutSort headerName="Ações" />,
        accessor: "user",
        key: "user",
        render: (_, record: User) => {
          return (
            <EditParamsOfSalesman
              user={record}
              onLoadSalesmansRelatedToUser={onLoadSalesmansRelatedToUser}
            />
          );
        },
        fixed: "right",
      },
    ];
  }, []);

  return [...columns];
};
