import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { ruptureRuleMock } from "@/core/mocks/ruptureRuleMock";
import { salesmansToRuptureRule } from "@/core/mocks/userMock";
import { Rule, RuptureRule } from "@/core/models/rules";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

interface AccountState {
  rule: Rule;
  selectedRuptureRule: RuptureRule;
  has500Error: boolean;
  status: "loading" | "fulfilled" | "failed";
}

const initialState = {
  rule: {},
  selectedRuptureRule: {},
  has500Error: false,
} as AccountState;

export const getRuptureRule = createAsyncThunk(
  "ruptureRule/getRuptureRule",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return ruptureRuleMock;
    } else {
      try {
        const response = await axios.get(`/rupture-rules`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface GetSalesmansToRuptureRuleProps {
  salesman_ids: string[];
}

export const getSalesmansToRuptureRule = createAsyncThunk(
  "ruptureRule/getSalesmansToRuptureRule",
  async ({ salesman_ids }: GetSalesmansToRuptureRuleProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return salesmansToRuptureRule;
    } else {
      try {
        const response = await axios.post(`/user/salesman`, [...salesman_ids]);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateRuptureRuleProps {
  rule_id: number;
  relationships: RuptureRule[];
}

export const updateRuptureRule = createAsyncThunk(
  "ruptureRule/updateRuptureRule",
  async (body: UpdateRuptureRuleProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(
          `/rupture-rules/${body.rule_id}`,
          body
        );
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface EnableRuleProps {
  rule_id: number;
  enabled: boolean;
}

export const enableRule = createAsyncThunk(
  "ruptureRule/enableRule",
  async (body: EnableRuleProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(
          `/rupture-rules/${body.rule_id}?enabled=${body.enabled}`
        );
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const ruptureRuleSlice = createSlice({
  name: "ruptureRuleSlice",
  initialState,
  reducers: {
    setSelectRuptureRule(state, action: PayloadAction<RuptureRule>) {
      state.selectedRuptureRule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRuptureRule.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      getRuptureRule.fulfilled,
      (state, action: PayloadAction<Rule[]>) => {
        const rule = action.payload[0];

        rule.rupture_rule?.relationships?.forEach((relationship, index) => {
          if (!relationship.id) {
            relationship.id = uuidv4();
          }
          if (!relationship.name) {
            relationship.name = `Regra sem nome ${index + 1}`;
          }
        });

        state.rule = rule;
        state.status = "fulfilled";
      }
    );
    builder.addCase(
      getRuptureRule.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500Error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting rupture rule");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateRuptureRule.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateRuptureRule.fulfilled, (state) => {
      state.status = "fulfilled";
    });
    builder.addCase(
      updateRuptureRule.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500Error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating rupture rule");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(enableRule.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(enableRule.fulfilled, (state) => {
      state.status = "fulfilled";
    });
    builder.addCase(
      enableRule.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500Error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error enabling rupture rules");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectRuptureRule = (state: RootState) =>
  state.ruptureRuleState.rule;
export const selectSelectedRuptureRule = (state: RootState) =>
  state.ruptureRuleState.selectedRuptureRule;
export const selectRuptureRuleStatus = (state: RootState) =>
  state.ruptureRuleState.status;
export const selectRuptureRuleHas500error = (state: RootState) =>
  state.ruptureRuleState.has500Error;

export const { setSelectRuptureRule } = ruptureRuleSlice.actions;

export const ruptureRuleReducer = ruptureRuleSlice.reducer;
