import { selectClient } from "@/features/clientInfo/store/clientSlice";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { ProfileInfo } from "../../../attendance/components/ProfileInfo";
import { useClientInfoMixpanel } from "../../hooks/useClientInfoMixpanel";
import UI from "../../ui";

export function ClientProfileActivityAreaData() {
  const selectedClient = useSelector(selectClient);

  const firstCnae = !selectedClient.cnae
    ? []
    : selectedClient.cnae && selectedClient.cnae.slice(0, 1);
  const otherCnaes = !selectedClient.cnae
    ? []
    : selectedClient.cnae && selectedClient.cnae.slice(1);

  const { clientProfileActivityAreaDataClickedEvent } = useClientInfoMixpanel();

  return (
    <Accordion type="single" collapsible width="full">
      <AccordionItem value="item-1">
        <AccordionTrigger
          onClick={() =>
            clientProfileActivityAreaDataClickedEvent(
              selectedClient.activity,
              [...firstCnae, ...otherCnaes],
              selectedClient.id
            )
          }
        >
          <Text>Atividade Econômica</Text>
        </AccordionTrigger>
        <AccordionContent>
          <UI.ClientInfoWrapper>
            {!isEmpty(selectedClient.activity) &&
            !isUndefined(selectedClient.activity) ? (
              <ProfileInfo
                label="Ramo de atividade"
                value={`${selectedClient.activity.id} - ${selectedClient.activity.activity}`}
              />
            ) : (
              <ProfileInfo label="Ramo de atividade" value="Não consta" />
            )}
            {!isUndefined(firstCnae) && !isEmpty(firstCnae) ? (
              <ProfileInfo
                label="CNAE"
                value={`${firstCnae[0].cnae_description} - ${firstCnae[0].cnae_name}`}
              />
            ) : (
              <ProfileInfo label="CNAE" value="Não consta" />
            )}

            {otherCnaes &&
              otherCnaes.map((cnae, i) => {
                return (
                  <div key={cnae.cnae_description}>
                    {!isEmpty(cnae) && !isUndefined(cnae) ? (
                      <ProfileInfo
                        label={`CNAE - ${i + 1}`}
                        value={`${cnae.cnae_description} - ${cnae.cnae_name}`}
                      />
                    ) : (
                      <ProfileInfo
                        label={`CNAE - ${i + 1}`}
                        value="Não consta"
                      />
                    )}
                  </div>
                );
              })}
          </UI.ClientInfoWrapper>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
