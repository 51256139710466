import { FormControllerInput } from "@/components/form/FormControllerInput";
import { FormControllerRadioInvertedValue } from "@/components/form/FormControllerRadioInvertedValue";
import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { User } from "@/core/models/user.model";
import { useProfileMixpanel } from "@/features/profile/hooks/useProfileMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Button,
  DialogClose,
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  gvTheme,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RiInformationLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  ParamsOfSalesmanFormData,
  paramsOfSalesmanFormSchema,
} from "../../hooks/paramsOfSalesmanFormSchema";
import { useSetValuesOnParamsOfSalesmanForm } from "../../hooks/useSetValuesOnParamsOfSalesmanForm";
import {
  selectSelectedSalesmansToChangeParams,
  setSelectedSalesmansToChangeParams,
  updateUserParams,
} from "../../store/paramsSlice";
import { DaysWithRuptureFormField } from "../DaysWithRuptureFormField";

interface ChangeParamsOfOneSalesmanFormProps {
  salesman: User;
  onLoadSalesmansRelatedToUser: () => Promise<void>;
  onCloseDialog: () => void;
}

export function ChangeParamsOfOneSalesmanForm({
  salesman,
  onLoadSalesmansRelatedToUser,
  onCloseDialog,
}: ChangeParamsOfOneSalesmanFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();
  const { changeParamsOfSalesmanEvent } = useProfileMixpanel();
  const { setDefaultValues, setValuesOnForm } =
    useSetValuesOnParamsOfSalesmanForm();

  const { handleSubmit, control, setValue, watch } =
    useForm<ParamsOfSalesmanFormData>({
      mode: "onBlur",
      resolver: yupResolver(paramsOfSalesmanFormSchema),
      defaultValues: setDefaultValues(salesman),
    });

  const onSubmit: SubmitHandler<ParamsOfSalesmanFormData> = async (
    data: ParamsOfSalesmanFormData
  ): Promise<void> => {
    setIsLoading(true);

    interface BodyProps {
      user_ids: number[];
      params: ParamsOfSalesmanFormData;
    }

    const body: BodyProps = {
      user_ids: [...[salesman.id]],
      params: {
        ...data,
      },
    };

    await dispatch(updateUserParams(body)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        onLoadSalesmansRelatedToUser().then(async () => {
          onCloseDialog();
          await dispatch(setSelectedSalesmansToChangeParams([]));
          changeParamsOfSalesmanEvent(body.user_ids, body.params);
          toast.success("Parâmetros alterados com successo");
        });
      }
    });

    setIsLoading(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar os parâmetros do vendedor");
    }
  };

  useEffect(() => {
    setValuesOnForm(setValue, salesman);
  }, [salesman]);

  return (
    <Container>
      <FormContainer>
        <FormControllerInput
          control={control}
          label="Dias de trabalho por mês"
          name="meta_parameters.work_metrics.work_days_per_month"
          placeholder="Ex. 22"
          type="number"
        />

        <Flex css={{ gap: "$4" }}>
          <FormControllerInput
            control={control}
            label="Horas trabalhadas por dia"
            name="meta_parameters.work_metrics.work_hours_per_day"
            placeholder="Ex. 8"
            type="number"
          />
          <FormControllerInput
            control={control}
            label="Horas úteis de trabalho"
            name="meta_parameters.work_metrics.effective_work_hours"
            placeholder="Ex. 7"
            type="number"
          />
        </Flex>

        <FormControllerInput
          control={control}
          label="Tempo médio de atendimento (em minutos)"
          name="meta_parameters.work_metrics.average_attendance_time"
          placeholder="Ex. 15"
          type="number"
        />

        <Flex css={{ gap: "$4", width: "100%" }}>
          <Controller
            control={control}
            name="meta_parameters.work_metrics.max_visit"
            render={({ field, fieldState: { error } }) => {
              const effective_work_hours = watch(
                "meta_parameters.work_metrics.effective_work_hours"
              );
              const average_attendance_time = watch(
                "meta_parameters.work_metrics.average_attendance_time"
              );

              const showSuggestion =
                average_attendance_time && effective_work_hours;
              let suggestion = 0;

              if (String(average_attendance_time) !== "0") {
                suggestion = Math.floor(
                  (effective_work_hours * 60) / average_attendance_time
                );
              } else {
                suggestion = 0;
              }

              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Capacidade de clientes por dia" />
                  <Flex
                    css={{ alignItems: "center", gap: "$2", width: "100%" }}
                  >
                    <Box css={{ width: "220px" }}>
                      <FormInputField
                        placeholder="Ex. 25"
                        error={error}
                        field={field}
                        type="number"
                      />
                    </Box>

                    {showSuggestion && (
                      <Flex css={{ alignItems: "center", gap: "$1" }}>
                        <Text>Sugestão: {suggestion} clientes por dia</Text>
                        <Tooltip>
                          <TooltipTrigger style={{ background: "transparent" }}>
                            <RiInformationLine color={gvTheme.colors.gray700} />
                          </TooltipTrigger>
                          <TooltipContent
                            style={{ zIndex: "9999" }}
                            aria-label="Selecione ao menos um usuário para alterar os parâmetros de atendimento"
                          >
                            Esse valor foi sugerido pelo govendas atráves de
                            análise de suas configurações
                          </TooltipContent>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              );
            }}
          />
        </Flex>

        <FormControllerRadioInvertedValue
          control={control}
          label="Permitir bloqueados"
          name="ignore_blocked"
        />

        <FormControllerRadioInvertedValue
          control={control}
          label="Agendar inadimplentes"
          name="meta_parameters.skip_defaulting"
          additionalOptions={[{ title: "Indefinido", value: null }]}
        />

        <Flex css={{ gap: "$4" }}>
          <FormControllerInput
            control={control}
            label="Frequência de clientes ativos"
            name="meta_parameters.active_freq"
            placeholder="Ex. 7"
            type="number"
          />
        </Flex>

        <Flex css={{ gap: "$4" }}>
          <FormControllerInput
            control={control}
            label="Dias sem compras para inativos"
            name="inactive_days"
            placeholder="Ex. 90"
            type="number"
          />

          <FormControllerInput
            control={control}
            label="Frequência de clientes inativos"
            name="meta_parameters.inactive_freq"
            placeholder="Ex. 14"
            type="number"
          />
        </Flex>

        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <FormControllerInput
            control={control}
            label="Frequência de clientes em ruptura"
            name="meta_parameters.rupture_params.freq"
            placeholder="Ex. 14"
            type="number"
          />

          <Controller
            control={control}
            name="meta_parameters.rupture_params"
            render={({ field: { value, onChange } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Dias com agenda de ruptura" />
                  <DaysWithRuptureFormField value={value} onChange={onChange} />
                </Flex>
              );
            }}
          />
        </Flex>
      </FormContainer>

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
          mt: "$4",
        }}
      >
        <DialogClose style={{ background: "none" }}>
          <Button bordered>Cancelar</Button>
        </DialogClose>

        {selectedSalesmansToChangeParams.length === 0 ? (
          <Tooltip>
            <TooltipTrigger style={{ background: "transparent" }}>
              <Button
                isLoading={isLoading}
                disabled
                onClick={() => handleSubmit(onSubmit, onError)()}
              >
                Salvar
              </Button>
            </TooltipTrigger>
            <TooltipContent
              style={{ zIndex: "9999" }}
              aria-label="Selecione ao menos um usuário para alterar os parâmetros de atendimento"
            >
              Selecione ao menos um usuário para alterar os parâmetros de
              atendimento
            </TooltipContent>
          </Tooltip>
        ) : (
          <Button
            isLoading={isLoading}
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            Salvar
          </Button>
        )}
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
  margin-top: ${(props) => props.theme.space[2]};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  overflow-y: scroll;
  padding: 4px;
  max-height: 500px;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 380px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 300px;
  }
`;
