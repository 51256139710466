import { EmptyContent } from "@/components/EmptyContent";
import { User } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import {
  getLastHistoryOfParamsByUsers,
  selectLastHistoryOfParamsByUsers,
} from "@/features/params/store/historyOfParamsSlice";
import { setSelectedSalesmanToChangeClientParams } from "@/features/params/store/paramsSlice";
import { UserProfileHeaderToPage } from "@/features/profile/components/UserProfileHeaderToPage";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Box, Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HistoryPageSkeleton } from "../components/HistoryPageSkeleton";
import { HistoryParamsByUsersTable } from "../components/HistoryParamsByUsersTable";
import { useHistoryParamsByUsersColumns } from "../hooks/useHistoryParamsByUsersColumns";

export function HistoryParamsByUsersForClientPage() {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const selectedClient = useSelector(selectClient);
  const lastHistoryOfParamsByUsers = useSelector(
    selectLastHistoryOfParamsByUsers
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function loadHistoryParams() {
    setIsLoading(true);

    await dispatch(
      getLastHistoryOfParamsByUsers({
        client_id: selectedClient.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsLoading(false);
      }
    });
  }

  const cleanSelectedUser = async () => {
    await dispatch(setSelectedSalesmanToChangeClientParams({} as User));
  };

  useEffect(() => {
    if (user.role === "SALESMAN") {
      navigate(`/info/client/${selectedClient.id}/params-history/${user.id}`);
    } else {
      cleanSelectedUser();
      loadHistoryParams();
    }
  }, []);

  const historyColumns = useHistoryParamsByUsersColumns();

  return (
    <Box css={{ mt: "$3" }}>
      {!isLoading ? (
        <>
          {lastHistoryOfParamsByUsers &&
          lastHistoryOfParamsByUsers.length === 0 ? (
            <EmptyContent size="medium">
              Este cliente não possui histórico de alteração de parâmetros para
              nenhum vendedor
            </EmptyContent>
          ) : (
            <Flex css={{ flexDirection: "column", gap: "$3" }}>
              <UserProfileHeaderToPage
                title="Histórico de parâmetros dos vendedores"
                description={`Aqui você consegue ver a última alteração de parâmetros dos vendores para o cliente: ${selectedClient.name}`}
              />

              <HistoryParamsByUsersTable
                columns={historyColumns}
                data={lastHistoryOfParamsByUsers}
                isLoading={isLoading}
              />
            </Flex>
          )}
        </>
      ) : (
        <HistoryPageSkeleton />
      )}
    </Box>
  );
}
