import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { User } from "@/core/models/user.model";
import {
  getSalesmansRelatedToUser,
  selectUser,
} from "@/core/store/users/usersSlice";
import { emitEventToChangeClientsFilters } from "@/features/attendance/store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { MdSettingsInputComponent } from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ChangeParamsDialog } from "../components/ChangeParamsDialog";
import { ParamsClients } from "../components/ParamsClients";
import { useBreadcrumbsForParamsPage } from "../hooks/useBreadcrumbsForParamsPage";
import { removeAllClientToChangeParams } from "../store/clientSelectedToChangeParamsSlice";
import {
  changeParams,
  setSelectedSalesmanToChangeClientParams,
} from "../store/paramsSlice";

export function ParamsClientsPage() {
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { breadcrumbsForClientsParams } = useBreadcrumbsForParamsPage();

  const handleUpdateParams = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(false));
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const loadSalesmansRelatedToUser = async () => {
    setIsLoading(true);

    if (user.role === "SALESMAN") {
      await dispatch(setSelectedSalesmanToChangeClientParams(user));
      setIsLoading(false);
    } else {
      await dispatch(getSalesmansRelatedToUser({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const relatedUsers: User[] = res.payload;
            await dispatch(
              setSelectedSalesmanToChangeClientParams(relatedUsers[0])
            );
            setIsLoading(false);
          }
        }
      );
    }
  };

  const cleanValues = async () => {
    await dispatch(removeAllClientToChangeParams());
    await dispatch(changeParams({ params: "cleanAllFilters" }));
  };

  useEffect(() => {
    cleanValues();
    loadSalesmansRelatedToUser();
    breadcrumbsForClientsParams();
  }, []);

  return (
    <>
      <Container>
        <Flex css={{ flexDirection: "column", height: "100%" }}>
          <>{!isLoading ? <ParamsClients /> : <ClientsTableSkeletonPage />}</>
        </Flex>

        <ChangeParamsDialog onUpdateParams={handleUpdateParams}>
          <Flex
            css={{
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              color: "$white",
              background: "$primary500",
              position: "absolute",
              bottom: "5px",
              right: "5px",
            }}
          >
            <MdSettingsInputComponent size="1.5rem" color="inherit" />
          </Flex>
        </ChangeParamsDialog>
      </Container>
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};
`;
