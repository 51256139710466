import { User } from "@/core/models/user.model";
import { getSpecificUser, selectUser } from "@/core/store/users/usersSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import {
  getHistoryByUser,
  selectHistorByUser,
} from "@/features/params/store/historyOfParamsSlice";
import {
  selectSelectedSalesmanToChangeClientParams,
  setSelectedSalesmanToChangeClientParams,
} from "@/features/params/store/paramsSlice";
import { UserProfileHeaderToPage } from "@/features/profile/components/UserProfileHeaderToPage";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Box, Flex, IconButton } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HistoryPageSkeleton } from "../components/HistoryPageSkeleton";
import { TimelineOfParams } from "../components/TimelineOfParams";

export function HistoryParamsByUserForClientPage() {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const selectedClient = useSelector(selectClient);
  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeClientParams
  );
  const historyOfParams = useSelector(selectHistorByUser);

  const { user_id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/info/client/${selectedClient.id}/params-history/list`);
  };

  async function loadHistoryParams(salesman: User) {
    setIsLoading(true);

    await dispatch(
      getHistoryByUser({
        client_id: selectedClient.id,
        user_id: salesman.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsLoading(false);
      } else {
        navigate(`/info/client/${selectedClient.id}/params-history/list`);
      }
    });
  }

  const loadSalesman = async () => {
    setIsLoading(true);

    if (user.role === "SALESMAN") {
      await dispatch(setSelectedSalesmanToChangeClientParams(user));
      loadHistoryParams(user);
      setIsLoading(false);
    } else {
      await dispatch(getSpecificUser({ user_id: Number(user_id) })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(
              setSelectedSalesmanToChangeClientParams(res.payload)
            );
            loadHistoryParams(res.payload);
          } else {
            navigate(`/info/client/${selectedClient.id}/params-history/list`);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (
      selectedSalesmanToChangeParams &&
      !isEmpty(selectedSalesmanToChangeParams)
    ) {
      loadHistoryParams(selectedSalesmanToChangeParams);
    } else {
      loadSalesman();
    }
  }, []);

  return (
    <Box css={{ mt: "$3", height: "100%" }}>
      <Flex css={{ alignItems: "center", gap: "$2" }}>
        <IconButton size="small" type="ghost" bordered onClick={handleNavigate}>
          <BiChevronLeft />
        </IconButton>
        <UserProfileHeaderToPage
          title={`Histórico de parâmetros do vendedor`}
          description={`Aqui você consegue o histórico de alteração de parâmetros do vendor  para o cliente: ${selectedClient.name}`}
        />
      </Flex>

      <Box
        css={{
          height: "calc(100% - 10%)",
          overflowY: "scroll",
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        {!isLoading && historyOfParams ? (
          <TimelineOfParams params={historyOfParams} />
        ) : (
          <HistoryPageSkeleton />
        )}
      </Box>
    </Box>
  );
}
