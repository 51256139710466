import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import { selectSelectedSalesmanToChangeClientParams } from "@/features/params/store/paramsSlice";
import { UserProfileHeaderToPage } from "@/features/profile/components/UserProfileHeaderToPage";
import { Caption, Flex, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SelectSalesmanToGetChangeParams } from "./SelectSalesmanToGetChangeParams";

interface HeaderPageWithSalesmanToSelectProps {
  title: string;
  description: string;
}

export function HeaderPageWithSalesmanToSelect({
  title,
  description,
}: HeaderPageWithSalesmanToSelectProps) {
  const salesman = useSelector(selectSelectedSalesmanToChangeClientParams);

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <UserProfileHeaderToPage title={title} description={description} />

      <UserCannotHaveAcess roles={["SALESMAN"]}>
        <SelectSalesmanToGetChangeParams>
          <Flex
            css={{
              flexDirection: "column",
              padding: "0 1rem",
              width: "fit-content",
            }}
          >
            <Caption css={{ cursor: "pointer", textAlign: "left" }}>
              Vendedor selecionado:
            </Caption>
            <SelectUserButton>
              <Text>{salesman.name}</Text>
            </SelectUserButton>
          </Flex>
        </SelectSalesmanToGetChangeParams>
      </UserCannotHaveAcess>
    </Flex>
  );
}

export const SelectUserButton = styled.div`
  all: unset;
  min-width: 130px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1rem;
  height: 35px;
  gap: 6px;
  background: ${(props) => props.theme.colors.white};
  font-size: 0.813rem;
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;
